import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import GlobalContextProvider from "../context/GlobalContextProvider"

import theme from "../styles/theme/Theme"
import GlobalStyle from "../styles/global/Golbal"
import SolutionModal from "./Modals/SolutionModal"
import SiteWrapper from "./SiteWrapper"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import MobileNav from "./MobileNav/MobileNav"

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <GlobalContextProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <SolutionModal />
        <SiteWrapper>
          <Header
            location={location}
            siteTitle={data.site.siteMetadata.title}
          />
          <MobileNav />
          <main>{children}</main>
          <Footer />
        </SiteWrapper>
      </ThemeProvider>
    </GlobalContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
