import React from "react"
import styled from "styled-components"
import HeaderNav from "./HeaderNav"
import HeaderLogo from "./HeaderLogo"
import { medWrapper } from "../../styles/helpers"

const HeaderStyled = styled.header`
  position: relative;
  z-index: 999999;

  .headerWrap {
    ${medWrapper};
    align-items: center;
  }
`

const Header = ({ siteTitle, location }) => {
  return (
    <HeaderStyled>
      <div className="headerWrap">
        <HeaderLogo siteTitle={siteTitle} />
        <HeaderNav location={location} />
      </div>
    </HeaderStyled>
  )
}

export default Header
