import React, { useContext } from "react"
import styled from "styled-components"
import { GlobalStateContext } from "../context/GlobalContextProvider"

const SiteWrapperDiv = styled.div`
  ${props => (props.isActive ? "filter: blur(5px) grayscale(50%);" : null)};
`

const SiteWrapper = ({ children }) => {
  const state = useContext(GlobalStateContext)
  return (
    <SiteWrapperDiv isActive={state.modalActive}>{children}</SiteWrapperDiv>
  )
}

export default SiteWrapper
