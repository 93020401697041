import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Nav1LimeGreen, colors } from "../../styles/helpers"

const getData = graphql`
  {
    footerLogo: wordpressAcfOptions {
      options {
        kon_footer_logo {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 350) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }

    footerMenu: wordpressWpApiMenusMenusItems(name: { eq: "Footer Menu" }) {
      items {
        wordpress_id
        title
        object_slug
        target
        type
        url
      }
    }
  }
`

const FooterNavStyled = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding-bottom: 7.5rem;
  z-index: 15;

  .innerWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 75rem;
    border-bottom: solid 0.1rem ${colors.colorPrimary};
  }

  .logo {
    width: 100%;
    max-width: 22.5rem;
    margin: auto;
    padding-bottom: 2rem;

    @media (min-width: 768px) {
      margin: 0;
      padding-left: 2rem;
      padding-right: 5rem;
      border-right: solid 0.1rem ${colors.colorPrimary};
    }
  }

  .navigation {
    display: none;
    padding-left: 5rem;

    @media (min-width: 768px) {
      display: block;
      padding-left: 2rem;
    }

    @media (min-width: 1025px) {
      padding-left: 5rem;
    }

    nav {
      a {
        ${Nav1LimeGreen};
        margin: 0 1.5rem;
        padding: 0 1.5rem;

        &:hover {
          color: ${colors.white};
        }
      }

      a:first-of-type {
        margin-left: 0;
        padding-left: 0;
      }
    }
  }
`

const FooterNav = () => {
  const data = useStaticQuery(getData)
  const { footerLogo, footerMenu } = data

  return (
    <FooterNavStyled>
      <div className="innerWrapper">
        <div className="logo">
          <Link to="/">
            <Img
              fluid={
                footerLogo.options.kon_footer_logo.localFile.childImageSharp
                  .fluid
              }
              alt={footerLogo.options.kon_footer_logo.alt_text}
            />
          </Link>
        </div>
        <div className="navigation">
          <nav>
            {footerMenu.items.map(item => {
              console.log(item)
              return (
                <Link key={item.wordpress_id} to={`/${item.object_slug}`}>
                  {item.title}
                </Link>
              )
            })}
          </nav>
        </div>
      </div>
    </FooterNavStyled>
  )
}

export default FooterNav
