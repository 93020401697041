import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import BlueChameleon from "../Chameleons/BlueChameleon"
import SvgBlueChameleon from "../Chameleons/SvgBlueChameleon"
gsap.registerPlugin(ScrollTrigger)

const FooterChameleonSection = styled.div`
  position: absolute;
  top: -2.5rem;
  right: -2rem;
  bottom: auto;
  width: calc(47.9rem / 2);
  height: calc(23.4rem / 2);
  z-index: 10;

  @media (min-width: 768px) {
    top: -5rem;
    right: -3rem;
    bottom: auto;
    width: calc(47.9rem / 1.25);
    height: calc(23.4rem / 1.25);
  }

  @media (min-width: 1025px) {
    top: auto;
    right: -4rem;
    bottom: 20rem;
    width: 47.9rem;
    height: 23.4rem;
  }
`

const FooterChameleon = () => {
  useEffect(() => {
    const triggerElement = document.querySelector("#footerChameleon")
    const chameleonSvg = document.querySelector(".blue-cham")
    const chameleonSkinOne = [
      ...chameleonSvg.querySelectorAll(".chamSpine path"),
    ]
    const arrayOne = chameleonSkinOne.slice(0, 4)
    const arrayTwo = chameleonSkinOne.slice(4, 8)
    const arrayThree = chameleonSkinOne.slice(8, 12)
    const arrayFour = chameleonSkinOne.slice(12, 15)

    // colors --> D8DF25, C1C80E, A2A900, B2B900

    gsap
      .timeline({
        scrollTrigger: {
          trigger: triggerElement,
          markers: false,
          start: "top 45%",
          toggleActions: "play none none reverse",
        },
      })
      .add("start")
      .to(
        [arrayOne],
        {
          fill: "#D8DF25",
          stagger: {
            each: 0.5,
            ease: "none",
          },
        },
        "start"
      )
      .to(
        [arrayTwo],
        {
          fill: "#A2A900",
          stagger: {
            each: 0.5,
            ease: "none",
          },
        },
        "start+=0.5"
      )
      .to(
        [arrayThree],
        {
          fill: "#C1C80E",
          stagger: {
            each: 0.5,
            ease: "none",
          },
        },
        "start+=1"
      )
      .to(
        [arrayFour],
        {
          fill: "#B2B900",
          stagger: {
            each: 0.5,
            ease: "none",
          },
        },
        "start+=1.5"
      )
  }, [])

  return (
    <FooterChameleonSection id="footerChameleon">
      <SvgBlueChameleon />
    </FooterChameleonSection>
  )
}

export default FooterChameleon
