import React from "react"

import styled from "styled-components"
import { medWrapper, colors } from "../../styles/helpers"

import FooterNav from "./FooterNav"
import FooterCopy from "./FooterCopy"
import FooterChameleon from "./FooterChameleon"

const FooterSection = styled.footer`
  position: relative;
  padding: 10rem 0 1rem;

  @media (min-width: 768px) {
    padding: 15rem 0 1rem;
  }

  @media (min-width: 1025px) {
    padding: 7.5rem 0 1rem;
  }

  .footerBG {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(0% 10%, 0% 100%, 100% 100%, 100% 0%);
    background-image: linear-gradient(
      to bottom,
      ${colors.colorSecondary},
      #071d32
    );
    z-index: 1;
  }

  .wrapper {
    position: relative;
    ${medWrapper};
    z-index: 25;
  }
`

const Footer = () => {
  return (
    <FooterSection>
      <div className="wrapper">
        <FooterNav />
        <FooterCopy />
      </div>

      <FooterChameleon />
      <div className="footerBG" />
    </FooterSection>
  )
}

export default Footer
