import React, { useContext } from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"

import { colors, H2DarkGrey, B1DarkGrey } from "../../styles/helpers"

const ModalItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.5s ease-in-out;
  transform-origin: center;
  transform: ${props =>
    props.isActive ? "scale(1) translateX(0%)" : "scale(0) translateX(-150%)"};
  background: transparent;
  z-index: 999999999;

  .modalInside {
    position: absolute;
    top: 2%;
    right: 2%;
    bottom: 2%;
    left: 0;
    background: ${colors.white};

    &::before {
      position: absolute;
      top: 25%;
      left: 0;
      width: 30%;
      height: 8.7rem;
      background-color: ${colors.colorSecondary};
      content: "";
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 90%;
      height: 2.2rem;
      background-color: ${colors.colorPrimary};
      content: "";
    }
  }

  .itemWrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 4rem;
    overflow: scroll;
  }

  .itemImage {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: calc(100% - 5rem);
    margin: auto 2.5rem;

    @media (min-width: 768px) {
      width: calc(75%);
      margin: 0;
    }

    @media (min-width: 1025px) {
      width: calc(80%);
      height: 100%;
    }

    .itemImageContainer {
      width: 100%;
      margin: auto;
    }
  }

  .itemContent {
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(25%);
    }

    @media (min-width: 1025px) {
      width: calc(20% - 4rem);
      margin-right: 2rem;
      margin-left: 2rem;
    }

    h2 {
      ${H2DarkGrey};
    }

    p {
      ${B1DarkGrey};
    }
  }

  .buttonWrapper {
    position: absolute;
    right: 0;

    button {
      width: 3.5rem;
      height: 3.5rem;
      transition: all 0.3s ease;
      background-color: ${colors.colorPrimary};
      cursor: pointer;
      border: none;

      &:hover {
        background-color: ${colors.colorSecondary};
        color: ${colors.white};
      }
    }
  }
`

const SolutionModal = () => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  return (
    <ModalItem isActive={state.modalActive}>
      {state.modalData && (
        <div className="modalInside">
          <div className="buttonWrapper">
            <button
              onClick={() => {
                dispatch({ type: "TOGGLE_ACTIVE", modalData: null })
              }}
            >
              &#10005;
            </button>
          </div>
          <div className="itemWrap">
            <div className="itemImage">
              <div className="itemImageContainer">
                <Img
                  fluid={state.modalData.image.localFile.childImageSharp.fluid}
                  alt={state.modalData.title}
                />
              </div>
            </div>
            <div className="itemContent">
              <div>
                <h2>{state.modalData.title}</h2>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: state.modalData.content }}
              />
            </div>
          </div>
        </div>
      )}
    </ModalItem>
  )
}

export default SolutionModal
