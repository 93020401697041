import React from "react"
import styled from "styled-components"

const BlueStyled = styled.div`
  svg {
    width: 100%;
  }
  .st0 {
    fill: #656668;
  }

  .st1 {
    fill: none;
  }

  .st2 {
    clip-path: url(#SVGID_2_);
  }

  .st3 {
    fill: #1d64af;
  }

  .st4 {
    fill: #004a7f;
  }

  .st5 {
    fill: #00548d;
  }

  .st6 {
    fill: #003a69;
  }

  .st7 {
    fill: #004f86;
  }

  .st8 {
    fill: #0077d0;
  }

  .st9 {
    fill: #0071ce;
  }

  .st10 {
    fill: #19579a;
  }

  .st11 {
    fill: #00538c;
  }

  .st12 {
    fill: #1c5fa7;
  }

  .st13 {
    fill: #1b5ba0;
  }

  .st14 {
    fill: #007ed3;
  }

  .st15 {
    fill: #0060a0;
  }

  .st16 {
    fill: #005995;
  }

  .st17 {
    fill: #005e9c;
  }

  .st18 {
    fill: #418bd8;
  }

  .st19 {
    fill: #5292db;
  }

  .st20 {
    fill: #007ad1;
  }

  .st21 {
    fill: #3487d7;
  }

  .st22 {
    fill: #414042;
  }

  .st23 {
    fill: #006bb1;
  }

  .st24 {
    fill: #185293;
  }
`

const SvgBlueChameleon = () => {
  return (
    <BlueStyled>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0"
        y="0"
        enableBackground="new 0 0 526 255.1"
        version="1.1"
        viewBox="0 0 526 255.1"
        xmlSpace="preserve"
        className="blue-cham"
      >
        <path
          d="M18.5 28.6L27.9 41.4 5 44.3 5 49.7 32.4 47.8 59.6 56.9 118.8 98.5 169.7 128 269.9 124.1 403.4 165.7 459.1 200.8 452.8 202.8 463.8 211 471.7 208.7 526 224.4 526 187.8 476.6 185.2 399.4 143.6 328.2 131.2 288.4 114.3 264.1 106.1 254.1 107.9 258.9 110.7 175.3 115.2 140.3 98.5 68.7 47.8 38.3 39.5 24.3 24.4z"
          className="st0"
        ></path>
        <g>
          <path d="M160.7 73.4H194.1V87H160.7z" className="st1"></path>
          <defs>
            <path id="SVGID_1_" d="M160.7 73.4H194.1V87H160.7z"></path>
          </defs>
          <clipPath>
            <use overflow="visible" xlinkHref="#SVGID_1_"></use>
          </clipPath>
          <image
            width="140"
            height="58"
            enableBackground="new"
            overflow="visible"
            transform="matrix(.24 0 0 .24 160.644 73.26)"
            xlinkHref="8B1A3677C0AA4A1C.png"
            className="0"
          ></image>
          <path
            d="M160.7 73.4L168.6 87 188 84.9 194.1 78.9z"
            className="st3"
          ></path>
          <path d="M168.6 87L162.7 103.2 146.7 88.1z" className="st4"></path>
          <path d="M188 84.9L162.7 103.2 168.6 87z" className="st5"></path>
          <path d="M141.7 95.7L162.7 103.2 146.7 88.1z" className="st6"></path>
          <path d="M168.6 87L146.7 88.1 160.7 73.4z" className="st7"></path>
          <path
            d="M151.1 47.8L146.7 88.1 160.7 73.4 169.7 64.3 169.7 37.3z"
            className="st8"
          ></path>
          <path d="M298.4 133L289.5 133 288.4 119.4z" className="st5"></path>
          <path d="M288.4 119.3L310 121.4 304.7 110z" className="st9"></path>
          <path
            d="M160.7 73.4L194.1 78.9 203.5 68.2 169.7 64.3z"
            className="st10"
          ></path>
          <path
            d="M210.4 87L177.6 106 168.6 104.3 206.5 80.2z"
            className="st11"
          ></path>
          <path
            d="M177.6 106L161.9 121.6 157.6 116.7 168.6 104.3z"
            className="st5"
          ></path>
          <path
            d="M168.6 104.3L157.6 116.7 146.3 107 162.7 103.2z"
            className="st4"
          ></path>
          <path d="M157.6 116.7L147.6 124.5 146.3 107z" className="st7"></path>
          <path d="M162.7 133L147.6 124.5 157.6 116.7z" className="st5"></path>
          <path
            d="M206.5 80.2L168.6 104.3 162.7 103.2 188 84.9 194.1 78.9z"
            className="st9"
          ></path>
          <path
            d="M194.1 78.9L206.5 80.2 215.6 73 203.5 68.2z"
            className="st8"
          ></path>
          <path d="M203.5 68.2L215.6 73 219.2 25.7z" className="st12"></path>
          <path d="M215.6 73L210.4 87 206.5 80.2z" className="st3"></path>
          <path
            d="M210.4 87L250.4 103.2 250.4 80 215.6 73z"
            className="st11"
          ></path>
          <path
            d="M250.4 80L289.5 93.2 310 84.9 272.2 36.4z"
            className="st8"
          ></path>
          <path d="M304.7 110L250.4 103.2 289.5 93.2z" className="st5"></path>
          <path d="M289.5 93.2L250.4 103.2 250.4 80z" className="st13"></path>
          <path d="M289.5 93.2L304.7 110 310 84.9z" className="st6"></path>
          <path
            d="M304.7 110L310 121.4 318.2 104.3 310 84.9z"
            className="st8"
          ></path>
          <path
            d="M320.8 116.7L306 133 298.4 133 310 121.4z"
            className="st9"
          ></path>
          <path d="M310 121.4L298.4 133 288.4 119.4z" className="st3"></path>
          <path
            d="M215.6 73L250.4 80 272.2 36.4 219.2 25.7z"
            className="st9"
          ></path>
          <path d="M272.2 36.4L310 84.9 311 56z" className="st12"></path>
          <path
            d="M169.7 37.3L169.7 64.3 203.5 68.2 219.2 25.7z"
            className="st9"
          ></path>
          <path
            d="M329 103.2L320.8 116.7 334.6 129.1 340.2 93.2z"
            className="st6"
          ></path>
          <path d="M334.6 129.1L345.8 115.8 340.2 93.2z" className="st3"></path>
          <path
            d="M311 56L310 84.9 318.2 104.3 324.9 48.7z"
            className="st9"
          ></path>
          <path
            d="M318.2 104.3L329 103.2 332.4 59.8 324.9 48.7z"
            className="st3"
          ></path>
          <path d="M329 103.2L340.2 93.2 332.4 59.8z" className="st7"></path>
          <path
            d="M309.2 255.1L295.6 237.9 278.1 230.7z"
            className="st3"
          ></path>
          <path
            d="M318.2 104.3L310 121.4 320.8 116.7 329 103.2z"
            className="st14"
          ></path>
          <path
            d="M334.6 129.1L367.8 164.4 345.8 115.8z"
            className="st9"
          ></path>
          <path
            d="M334.6 129.1L357.7 170.8 367.8 164.4z"
            className="st13"
          ></path>
          <path
            d="M359.6 220.5L328.2 237.9 354.4 216.6z"
            className="st7"
          ></path>
          <path
            d="M357.7 170.8L354.4 216.6 359.6 220.5 367.8 164.4z"
            className="st3"
          ></path>
          <path d="M359.6 220.5L350 241.6 328.2 237.9z" className="st3"></path>
          <path d="M309.2 255.1L350 241.6 328.2 237.9z" className="st15"></path>
          <path
            d="M295.6 237.9L309.2 255.1 328.2 237.9z"
            className="st5"
          ></path>
          <path d="M273 204.7L284.3 211 304.1 194.3z" className="st16"></path>
          <path
            d="M293.2 181.2L304.1 194.3 324.9 185.2z"
            className="st17"
          ></path>
          <path
            d="M304.1 194.3L328.9 207.7 324.9 185.2z"
            className="st9"
          ></path>
          <path
            d="M304.1 194.3L328.9 207.7 324.9 185.2z"
            className="st1"
          ></path>
          <path
            d="M337.3 197.9L328.9 207.7 324.9 185.2z"
            className="st8"
          ></path>
          <path d="M336.9 220.5L328.2 229 328.9 207.7z" className="st3"></path>
          <path d="M328.9 207.7L328.2 229 321.1 217.7z" className="st13"></path>
          <path d="M328.2 229L304.7 220.2 321.1 217.7z" className="st11"></path>
          <path
            d="M321.1 217.7L304.7 220.2 312.2 211.7z"
            className="st13"
          ></path>
          <path d="M312.2 211.7L304.7 220.2 304.1 211z" className="st5"></path>
          <path d="M312.2 204.7L312.2 211.7 304.1 211z" className="st4"></path>
          <path d="M295.6 237.9L278.1 230.7 284.3 211z" className="st7"></path>
          <path d="M273 204.7L278.1 230.7 284.3 211z" className="st9"></path>
          <path d="M304.1 194.3L273 204.7 293.2 181.2z" className="st9"></path>
          <path d="M309.2 255.1L345.8 252.5 350 241.6z" className="st14"></path>
          <path d="M309.2 147.9L289.5 133 298.4 133z" className="st4"></path>
          <path d="M309.2 147.9L298.4 133 306 133z" className="st7"></path>
          <path d="M157.6 116.7L161.9 121.6 162.7 133z" className="st7"></path>
          <path d="M147.9 25.7L151.1 47.8 169.7 37.3z" className="st18"></path>
          <path d="M250.4 103.2L210.4 87 188 99.9z" className="st6"></path>
          <path
            d="M309.2 147.9L306.5 135.5 316.4 138.6z"
            className="st6"
          ></path>
          <path d="M162.7 133L162.1 123.6 167.6 126.8z" className="st6"></path>
          <path d="M194.1 100.2L198 107 209.3 101.1z" className="st4"></path>
          <path
            d="M198 107L186 112.4 182.1 120.8 202.2 113.8z"
            className="st4"
          ></path>
          <path d="M198 107L209.3 101.1 202.2 113.8z" className="st3"></path>
          <path
            d="M209.3 101.1L211.4 113.3 202.2 113.8z"
            className="st5"
          ></path>
          <path d="M198 133L188.8 123 211.4 126.4z" className="st6"></path>
          <path d="M284.3 211L295.6 237.9 303.8 230.7z" className="st13"></path>
          <path
            d="M295.6 237.9L328.2 237.9 303.8 230.7z"
            className="st3"
          ></path>
          <path d="M284.3 211L303.8 230.7 295.6 211.7z" className="st5"></path>
          <path d="M284.3 211L295.6 211.7 304.1 194.3z" className="st7"></path>
          <path
            d="M328.9 207.7L337.3 197.9 336.9 220.5z"
            className="st9"
          ></path>
          <path
            d="M312.2 204.7L314.9 206.2 312.2 211.7z"
            className="st5"
          ></path>

          <g className="chamSpine">
            <path
              d="M188.8 0L147.9 25.7 169.7 37.3 189.3 0z"
              className="st14"
            ></path>
            <path d="M190 0L219.2 25.7 263.4 0z" className="st19"></path>
            <path
              d="M264.2 0L272.2 36.4 314.8 21.1 265.1 0z"
              className="st8"
            ></path>
            <path
              d="M314.8 21.1L324.9 48.7 332.4 59.8 357.2 60.7z"
              className="st9"
            ></path>

            <path
              d="M189.3 0L169.7 37.3 219.2 25.7 190 0z"
              className="st18"
            ></path>
            <path
              d="M263.4 0L219.2 25.7 272.2 36.4 264.2 0z"
              className="st20"
            ></path>
            <path
              d="M272.2 36.4L311 56 324.9 48.7 314.8 21.1z"
              className="st21"
            ></path>
            <path d="M332.4 59.8L340.2 93.2 357.2 60.7z" className="st3"></path>

            <path
              d="M340.2 93.2L345.8 115.8 384.1 133 357.2 60.7z"
              className="st5"
            ></path>
            <path d="M367.8 164.4L389 193 384.1 133z" className="st9"></path>
            <path d="M389 193L359.6 220.5 367.8 164.4z" className="st11"></path>
            <path d="M389 193L373.4 233.3 350 241.6z" className="st7"></path>

            <path
              d="M345.8 115.8L367.8 164.4 384.1 133z"
              className="st3"
            ></path>
            <path d="M350 241.6L359.6 220.5 389 193" className="st4"></path>
            <path
              d="M373.4 233.3L345.8 252.5 350 241.6z"
              className="st9"
            ></path>
          </g>
        </g>
        <g className="chamHead">
          <path
            d="M151.1 47.8L147.6 47.8 141.4 14.3 146.1 11.5z"
            className="st13"
          ></path>
          <path
            d="M63.9 94.1L82.1 95.1 125.5 80.8 74.1 80.8z"
            className="st22"
          ></path>
          <path d="M114.4 49.7L101 55.7 103.4 43.9z" className="st22"></path>
          <path d="M101 30.7L103.4 43.9 121.2 35.7z" className="st3"></path>
          <path d="M101 55.7L112.7 68.2 114.4 49.7z" className="st3"></path>
          <path d="M92 65.2L112.7 68.2 101 55.7z" className="st9"></path>
          <path d="M87 43.9L92 65.2 101 55.7z" className="st3"></path>
          <path d="M103.4 43.9L114.4 49.7 121.2 35.7z" className="st13"></path>
          <path d="M87 43.9L101 55.7 103.4 43.9z" className="st9"></path>
          <path d="M87 43.9L103.4 43.9 101 30.7z" className="st23"></path>
          <path
            d="M77.9 38.7L87 43.9 101 30.7 101 27.5z"
            className="st9"
          ></path>
          <path
            d="M101 27.5L101 30.7 121.2 35.7 141.4 14.3 137.4 6.5z"
            className="st18"
          ></path>
          <path d="M146.7 88.1L141.8 95.7 140.3 84.9z" className="st6"></path>
          <path
            d="M147.6 47.8L140.3 84.9 146.7 88.1 151.1 47.8z"
            className="st24"
          ></path>
          <path d="M146.1 11.5L141.4 14.3 137.4 6.5z" className="st24"></path>
          <path
            d="M123.2 53.4L133.1 75.2 140.3 84.9 147.6 47.8z"
            className="st18"
          ></path>
          <path d="M147.6 47.8L121.2 35.7 141.4 14.3z" className="st14"></path>
          <path d="M114.4 49.7L123.2 53.4 121.2 35.7z" className="st24"></path>
          <path d="M112.7 68.2L123.2 53.4 114.4 49.7z" className="st10"></path>
          <path
            d="M112.7 68.2L112.7 73.4 133.1 75.2 123.2 53.4z"
            className="st3"
          ></path>
          <path d="M121.2 35.7L123.2 53.4 147.6 47.8z" className="st8"></path>
          <path
            d="M66 70.8L62.2 87 74.1 80.8 71.7 70.8z"
            className="st9"
          ></path>
          <path d="M133.1 75.2L125.5 80.8 112.7 73.4z" className="st9"></path>
          <path d="M71.7 70.8L92 65.2 87 43.9z" className="st9"></path>
          <path
            d="M66 70.8L71.7 70.8 87 43.9 77.9 38.7z"
            className="st14"
          ></path>
          <path d="M92 65.2L74.1 80.8 71.7 70.8z" className="st3"></path>
          <path d="M125.5 80.8L140.3 84.9 133.1 75.2z" className="st3"></path>
          <path
            d="M82.1 95.1L80.6 110 133.1 103.2 125.5 80.8z"
            className="st9"
          ></path>
          <path d="M63.9 94.1L80.6 110 82.1 95.1z" className="st3"></path>
          <path
            d="M74.1 80.8L112.7 73.4 112.7 68.2 92 65.2z"
            className="st13"
          ></path>
          <path
            d="M74.1 80.8L82.7 89.8 125.5 80.8 112.7 73.4z"
            className="st12"
          ></path>
          <path d="M62.2 87L82.7 89.8 74.1 80.8z" className="st3"></path>
          <path
            d="M125.5 80.8L133.1 103.2 141.7 95.7 140.3 84.9z"
            className="st14"
          ></path>
        </g>
      </svg>
    </BlueStyled>
  )
}

export default SvgBlueChameleon
