import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { B1Lime } from "../../styles/helpers"

const FooterCopySection = styled.div`
  text-align: center;
  width: 100%;

  .links {
    width: 100%;

    p,
    a {
      ${B1Lime};
      margin: 0;
    }
  }

  .copy {
    width: 100%;

    p,
    a {
      ${B1Lime};
      margin: 0;
    }
  }
`

const FooterCopy = () => {
  return (
    <FooterCopySection>
      <div className="links">
        <p>
          <Link to={"/privacy-policy"}>Privacy Policy</Link> |{" "}
          <Link to={"/disclaimer"}>Disclaimer</Link>
        </p>
      </div>
      <div className="copy">
        <p>
          Kona Solution Partners © {new Date().getFullYear()} All Rights
          Reserved
        </p>
        <p className="swbCred">
          Designed, and developed by{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://switchbackcreative.ca"
          >
            Switchback Creative
          </a>
          .
        </p>
      </div>
    </FooterCopySection>
  )
}

export default FooterCopy
