import React, { useReducer, createContext } from "react"

export const GlobalStateContext = createContext()
export const GlobalDispatchContext = createContext()

const initialState = {
  modalActive: false,
  modalData: null,
}

function reducerFunc(state, action) {
  switch (action.type) {
    case "TOGGLE_ACTIVE":
      return {
        ...state,
        modalActive: !state.modalActive,
        modalData: action.payload ? action.payload : state.modalData,
      }
    default:
      return state
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducerFunc, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
